import React, { useEffect } from 'react'

import { Chart } from 'primereact'
const MarketShare = ({ mainClassName }) => {

    const data = {
        labels: [
            '60% - John Deere',
            '26% - Ford - New Holland',
            '23% - Massey Ferguson',
            '10% - Varios',
            '3% - Ford',
            '2% - Case',
            '1% - Kubota',
        ],
        datasets: [
            {
                data: [10271, 5779, 1642, 649, 437, 351, 230],
                backgroundColor: [
                    '#367C2B',
                    '#3486eb',
                    '#be0032',
                    '#5c5c5c',
                    '#133A7C',
                    '#e8a12e',
                    '#eb603f',
                ],
            },
        ],
    };

    // Configuración del gráfico
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 1.8,
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    color: 'black',
                },
            },

        },
    };


    return (
        <div className={mainClassName}>
            <h6>Market Share por Tractores</h6>
            <Chart id="pie-chart" type="doughnut" data={data} options={options} style={{ paddingRight: '4rem' }} />
        </div>
    )
}

export default MarketShare