import React from 'react'
import IndicatorProb from './IndicatorProb'

const ProspectingProb = ({ mainClassName }) => {
    return (
        <div className={mainClassName}>
            <h6>Prospección por Probabilidad </h6>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', height: '90%' }}>
                <IndicatorProb
                    color={'rgb(21, 184, 6)'}
                    amount='23'
                    value={'Más del 80%'}
                />
                <IndicatorProb
                    color={'#e69019'}
                    amount='12'
                    value={'Arriba del 50%'}
                />
                <IndicatorProb
                    color={'red'}
                    amount='8'
                    value={'Con menos del 50%'}
                />

            </div>
        </div>
    )
}

export default ProspectingProb