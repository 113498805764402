import React from 'react'
import '../../../styles/OpsItem.css'

const OpsItem = ({ department, ops, amount }) => {
    return (
        <div className='item-content'>
            <span id='dept-name'>{department}</span>
            <span id='ops'>{ops} Ops.</span>
            <span id='total-amount'>{amount}</span>
        </div>
    )
}

export default OpsItem