import React from 'react'
import OpsItem from './OpsItem'

const Operations = ({ mainClassName }) => {
    return (
        <div className={mainClassName}>
            <h6>Facturación y  Operaciones</h6>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', height: '85%', gap: '1rem', padding: '1rem' }}>
                <OpsItem department="Ventas" ops="5257" amount="$ 2,098,279,519.73" />
                <OpsItem department="Acuacultura" ops="8" amount="$ 6,119,565.10" />
                <OpsItem department="AGP" ops="1039" amount="$ 162,126,972.07" />
                <OpsItem department="Agroferretera" ops="531" amount="$ 12,396,898.16" />
                <OpsItem department="Refacciones" ops="4578" amount="$ 127,641,414.15" />
                <OpsItem department="Servicio" ops="2698" amount="$ 44,385,112.51" />
            </div>
        </div>
    )
}

export default Operations