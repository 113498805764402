import React from 'react'
import '../../../styles/IndicatorProb.css'

const IndicatorProb = ({ color, value, amount }) => {
    return (
        <div className='indicator-container'>
            <div className='value' style={{ backgroundColor: color }}>
                {value}
            </div>
            <hr className="hrLinea" style={{ borderColor: color }} />
            <div className='amount' style={{ color: color, borderColor: color }} >
                {amount} Prospectos
            </div>
        </div>
    )
}

export default IndicatorProb