import React from 'react'
import '../../styles/firstlevel.css'
import MarketShare from './components/MarketShare'
import ProspectingProb from './components/ProspectingProb'
import ProspectingbyStatus from './components/ProspectingbyStatus'
import Visits from './components/Visits'
import Wallet from './components/Wallet'
import Operations from './components/Operations'
import Filters from './components/Filters'
import BudgetAndSold from './components/BudgetAndSold'

const Summary = ({ filters, setFilters }) => {

    return (
        <section className='grid-container'>


          <Filters 
               mainClassName='box filters' 
               filters={filters}
               setFilters={setFilters} 
          />

          <BudgetAndSold 
               mainClassName='box chart-presupuesto' 
               filters={filters}
          />

          <MarketShare 
               mainClassName='box chart-market-share' 
          />

          <ProspectingProb 
               mainClassName='box prospeccion-probabilidad' 
          />

          <ProspectingbyStatus 
               mainClassName='box prospeccion-status' 
          />

          <Visits 
               mainClassName='box visitas' 
          />

          <Operations 
               mainClassName='box operaciones' 
          />

          <Wallet 
               mainClassName='box cartera' 
          />

        </section>
    )
}

export default Summary