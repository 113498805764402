import axios from 'axios'

const SOURCE = '/backend/dashboardventas';
const BS_TRACTORS = '/rqstbs'

export const GetBudgetAndSold = async (filters) => {
     console.log('filtros: ',filters)
     let dataHandled = {}
     try {
          const response = await axios.get(`${SOURCE}${BS_TRACTORS}`,{
               params: {
                    sellers: '220',
                    from: '2024-01-01',
                    to:'2024-12-12'
               }
          })

          if (!response) throw new Error('Sin Datos')

          for (const key in response.data) {
               dataHandled[key] = JSON.parse(response.data[key]);
               dataHandled[key] = dataHandled[key].map(item => {
                    return {
                         Modelo: item.model,
                         Cantidad: item.amount
                    };
               }) 
          }

     } catch (error) {
          console.error(error)
     }
     finally{
          return dataHandled
     }
}