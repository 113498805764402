import React from 'react'

import { Chart } from 'primereact'
const generateShadesOfGreen = (baseColor, numberOfShades) => {
    const shades = [];
    const baseRGB = baseColor.match(/\w\w/g).map(x => parseInt(x, 16));

    for (let i = 5; i < numberOfShades; i++) {
        const luminosity = i / (numberOfShades - 1);
        const newColor = `rgb(${Math.round(baseRGB[0] + (255 - baseRGB[0]) * luminosity)}, ${Math.round(baseRGB[1] + (255 - baseRGB[1]) * luminosity)}, ${Math.round(baseRGB[2] + (255 - baseRGB[2]) * luminosity)})`;
        shades.push(newColor);
    }

    return shades;
};

const Visits = ({ mainClassName }) => {

    const baseColor = '#4cbaf1';
    const dataSeries = [10, 8, 15, 6, 12, 7, 9, 14, 5];
    const numberOfShades = dataSeries.length * 2
    const data = {
        labels: ['Posventa', 'Cobranza', 'Venta', 'Censo', 'Programación', 'Satisfacción del Cliente', 'Contacto después de la entrega', 'Programación ANUAL', 'Otros'],
        datasets: [
            {
                label: 'Cantidad',
                backgroundColor: generateShadesOfGreen(baseColor, numberOfShades),
                data: dataSeries
            }
        ]
    };
    const options = {
        responsive: true,
        mantainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            legend: {
                display: false,

            }
        }, elements: {
            bar: {
                borderWidth: 0,
            }
        },

        indexAxis: 'y',
        scales: {
            y: {
                display: true,
                ticks: {
                    mirror: true,
                    showLabelBackdrop: false,
                    z: 2,
                    color: 'rgba(0,0,0,0.7)',

                    autoSkip: false,
                    callback: function (val, index, as) {
                        return this.getLabelForValue(val) + ": " + dataSeries[val]
                    },
                },
            },

        }

    };



    return (
        <div className={mainClassName}>
            <h6>Visitas por Tipo</h6>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '90%', paddingTop: '1rem' }}>

                <Chart type="bar" data={data} options={options} />
                <span style={{ textAlign: 'end', color: 'rgba(0,0,0,0.7)', fontWeight: '600' }}>Total: 120</span>

            </div>
        </div >
    )
}

export default Visits