import {
    Button,
    Checkbox
} from 'primereact';

import useExportXLSX from '../general_hooks/useExportXLSX';

export const TableHeader = (textHeader, tableData, setGlobalFilter, onlyNoNotes, setOnlyNoNotes) => {

    const { exportExcel } = useExportXLSX();
    return (
        <div className="d-flex flex-row align-items-center justify-content-end gap-8">
            <h6 className="pt-1 mr-auto">{textHeader}</h6>
            <div className='d-flex gap-3 align-items-center'>
                <Checkbox
                    inputId="chkNoNotes"
                    checked={onlyNoNotes}
                    onChange={e => setOnlyNoNotes(e.checked)}
                />
                <label htmlFor='chkNoNotes'>Sin notas</label>
            </div>
            {/*<InputText*/}
            {/*    type="search"*/}
            {/*    onInput={(e) => setGlobalFilter(e.target.value)}*/}
            {/*    placeholder="Filtrar..."*/}
            {/*    className="p-mb-2 mr-3 p-inputtext-sm"*/}
               
            {/*/>*/}
            <Button
                label="Export"
                icon="pi pi-file-excel"
                iconPos="right"
                severity="success"
                onClick={() => exportExcel(tableData, 'SeguimientoSurtido')}
                size="small"
                rounded
            />
        </div>
    );
};