import React from 'react'
import { Chart } from 'primereact'

const ProspectingbyStatus = ({ mainClassName }) => {
    const data = {
        labels: ['En Proceso', 'Venta Realizada', 'Venta Perdida', 'Cancelada'],
        datasets: [
            {
                data: [30, 20, 15, 10], // Ajusta los valores según tus necesidades
                backgroundColor: ['#42A5F5', '#66BB6A', '#EF5350', '#FFC107'], // Colores asociados a cada indicador
            },
        ],
    };
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 1.8,
        plugins: {
            legend: {
                display: true,
                position: 'left',
            },

        },
    };
    return (
        <div className={mainClassName}>
            <h6>Prospección por Estatus</h6>

            <Chart type="pie" data={data} options={options} style={{ paddingLeft: '4rem' }} />
        </div>
    )
}

export default ProspectingbyStatus