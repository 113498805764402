import React, { useEffect, useState} from 'react'
import { Chart } from 'primereact'
import externalTooltipHandler from './CustomToolTip';
import { GetBudgetAndSold } from '../request';

const BudgetAndSold = ({ mainClassName, filters }) => {

    const [budget, setBudget] = useState({
        count: 0,
        detail: []
    })
    const [sold, setSold] = useState({
        count: 0,
        detail: []
    })
    const [tooltipDetail, setTooltipDetail] = useState([])

    useEffect(() => {
        const initialize = async() => {
            const response = await GetBudgetAndSold(filters);

            if(!response) return 

            const bCount = response.budget.reduce((sum, item) => sum + item.Cantidad, 0);
            const sCount = response.sold.reduce((sum, item) => sum + item.Cantidad, 0);

            setBudget({
                count: bCount,
                detail: response.budget
            })
            setSold({
                count: sCount,
                detail: response.sold
            })
            setTooltipDetail({
                [`Presupuesto: ${bCount}`]: response.budget,
                [`Vendidos: ${sCount}`]: response.sold
            })
        }
        
        initialize();
    }, [])
    
    const data = {
        labels: [`Presupuesto: ${budget.count}`, `Vendidos: ${sold.count}`],
        datasets: [
            {
                backgroundColor: ['#42A5F5', '#66BB6A'],
                data: [budget.count, sold.count],
            },
        ],
    };

    const options = {   
        responsive: true,
        aspectRatio: 1.8,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                position: 'average',
                external: (e) => { externalTooltipHandler(e, tooltipDetail) }
            }

        },
    };


    return (
        <div className={mainClassName}>
            <h6>Venta y Presupuesto de Tractores</h6>
            <Chart data={data} type="bar" options={options} style={{ width: '100%' }} />
        </div>
    )
}

export default BudgetAndSold