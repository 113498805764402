const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.width = '50wh';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const externalTooltipHandler = (context, tooltipDetail) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }
    // Set Text
    if (tooltip.body) {
        const tableHead = document.createElement('thead');
        const titleLines = tooltip.title || [];
        const data = tooltipDetail[titleLines]
        const headerRow = document.createElement('tr');
        const tableBody = document.createElement('tbody');

        titleLines.forEach(title => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;

            const th = document.createElement('th');
            th.style.borderWidth = 0;
            const text = document.createTextNode(title);

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });



        // Create rows with data
        for (const key in data[0]) {
            const th = document.createElement('th');
            th.appendChild(document.createTextNode(key));
            tableBody.appendChild(th);
        }

        tableHead.appendChild(headerRow);
        data.forEach(obj => {
            const tr = document.createElement('tr');
            for (const key in obj) {
                const td = document.createElement('td');
                td.style.minWidth = '10rem'
                td.appendChild(document.createTextNode(obj[key]));
                tr.appendChild(td);
            }
            tr.style.borderBottom = '1px solid gray'
            tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        // tableRoot.style.width = '1000px'
        tableRoot.style.fontSize = '80%'
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.zIndex = 2;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX * 2 + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export default externalTooltipHandler;