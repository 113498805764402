import React, { useEffect, useState } from 'react'
import './index.css'
import Summary from './sections/summary'

const Dashboard = () => {
    const [filters, setFilters] = useState({
        branch: null,
        employees: null,
        from: null,
        to: null
    })

    useEffect(()=>{
        document.title = "Dashboard Ventas Maquinaria"
    },[])
    return (
            <main className='main-content'>

                <Summary filters={filters} setFilters={setFilters} />
                {/* 
                <h5>Prepuesto y avance en repuestos</h5>
                <SparePartsBA />

                <h5>Perfil Crediticio</h5>
                <CreditProfile />

                <h5>Propeccion y Visita a Clientes</h5>
                <ProspectingandVisits /> */}

            </main>
    )
}

export default Dashboard    