/* eslint-disable react-hooks/exhaustive-deps */
// useSeguimientoSurtido.js
import { useEffect, useState, useRef } from 'react';
import { formatDate } from '../../../helpers/formatDate';

const useSeguimientoSurtido = (URL) => {
    const [timelineChartData, setTimelineChartData] = useState(null);
    const [estatusSurtido, setEstatusSurtido] = useState(null);
    const [filters, setFilters] = useState({ sucursal: null, status: null, pedido: null });
    const [stackedData, setStackedData] = useState({});
    const [tableData, setTableData] = useState([]);

    const getDescription = (idsurtidoestatus) => {
        const estatusObj = estatusSurtido.find(obj => obj.idsurtidoestatus === idsurtidoestatus);
        return estatusObj ? estatusObj.descripcion : 'Descripci�n no encontrada';
    };
    const getSeverity = (idsurtidoestatus) => {
        const estatusObj = estatusSurtido.find(obj => obj.idsurtidoestatus === idsurtidoestatus);
        return estatusObj ? estatusObj.severity : 'Descripci�n no encontrada';
    };
    const getId = (descripcion) => {
        const estatusObj = estatusSurtido.find(obj => obj.descripcion === descripcion);
        return estatusObj ? estatusObj.idsurtidoestatus : 'id no encontrado';
    };
    const getEstatues = () => {
        return estatusSurtido.map(obj => obj.descripcion);
    };

    useEffect(() => {
        fetch(URL + 'getIImportHistory')
            .then(response => response.json())
            .then(data => {
                const chartData = {
                    labels: data.map(item => formatDate(item.fecha)),
                    datasets: [
                        {
                            label: 'Cantidades',
                            data: data.map(item => item.monto),
                            borderColor: '#367C2B',
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 7,
                            pointHoverRadius: 20,
                        },
                    ],
                };
                setTimelineChartData(chartData);
            })
            .catch(error => {
                console.error('Error fetching import history data:', error);
            });
    }, [URL]);
    useEffect(() => {
        if (filters.sucursal != null && filters.status != null && filters.pedido != null)
            listData();

    }, [URL,filters])
    useEffect(() => {
        fetch(URL + 'getEstatusSurtido')
            .then(response => response.json())
            .then(data => {
                setEstatusSurtido(data);
            })
            .catch(error => {
                console.error('Error fetching surtido inmediato data:', error);
            });
    }, [URL]);

    const listData = () => {
        const url = `${URL}getSurtidoInmediato?sucursal=${filters.sucursal}&status=${filters.status}&pedido=${filters.pedido}`;

        fetch(url, {
            method: 'GET', // Cambiar a m�todo GET
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                const dataWithIndex = data.map((element, index) => {
                    return {
                        ...element,
                        index: index + 1
                    };
                });
                // me quede en el stackeddata falta pasarlo al componente para contar cada status
                setStackedData(data.reduce((accumulator, element) => {
                    const estatus = element.estatus;

                    if (estatus === 0) {
                        accumulator.na++;
                    } else if (estatus === 1) {
                        accumulator.pedidos++;
                    } else if (estatus === 2) {
                        accumulator.omitidos++;
                    } else if (estatus === 3) {
                        accumulator.pc++;
                    }

                    return accumulator;
                }, { na: 0, pedidos: 0, omitidos: 0, pc: 0 }))
                setTableData(dataWithIndex);
            })
            .catch(error => {
                console.error('Error fetching surtido inmediato data:', error);
            });
    }

    const actualizarPiezaSurtida = (id, numeroEstatus, comentario) => {

        const data = {
            id,
            numeroEstatus,
            comentario
        };

        fetch(URL + 'updatepiezasurtida', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (response.status === 200) {
                } else {
                    console.error('Error en la solicitud:', response.status);
                }
            })
            .catch(error => {
                console.error('Error en la solicitud:', error);
            });
        //listData();
    };

    const handleFilterChangeAndFetchData = (value, identifier) => {
        switch (identifier) {
            case 'sucursal':

                setFilters({
                    ...filters,
                    sucursal: value
                });
                break;
            case 'estatus':
                setFilters({
                    ...filters,
                    status: value
                });
                break;
            case 'pedido':
                setFilters({
                    ...filters,
                    pedido: value
                });;
                break;
            default:
                break;
        }
    };

    return {
        timelineChartData,
        tableData,
        getSeverity,
        setTableData,
        actualizarPiezaSurtida,
        getDescription,
        getEstatues,
        getId,
        handleFilterChangeAndFetchData,
        filters,
        stackedData
        // Add more data and functions as needed
    };
};

export default useSeguimientoSurtido;
