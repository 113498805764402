import React from 'react'
import DropdownBranch from '../../../../../../general_components/DropdownBranch'
import DatePickerRange from '../../../../../../general_components/DatePickerRange'
import DropdownEmployee from '../../../../../../general_components/DropdownEmployee'

const Filters = ({ mainClassName, filters, setFilters }) => {

    return (
        <div className={mainClassName}>
            <h6>Opciones de Filtrado</h6>
            <div style={{
                flexDirection: 'column',
                paddingTop: '1.5rem',
                display: 'flex',
                gap: '2rem'
            }}>
                <DropdownBranch
                    onValueChange={(e) => { setFilters({ ...filters, branch: e }) }}
                />
                <DropdownEmployee
                    onValueChange={(e) => { setFilters({ ...filters, employees: e }) }}
                    departments={[1]}
                    branch={filters.branch}
                />
                <DatePickerRange
                    onRangeChange={(e) => { setFilters({ ...filters, from: e.from, to: e.to }) }}
                    filters={filters}
                />
            </div>

        </div>
    )
}

export default Filters